import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import Slider from 'react-slick'
import * as Api from 'src/api'
import { BalanceLargePositiveIcon } from 'src/assets/icons/customIcons/BalanceLargePositive'
import { CreditsLargeIcon } from 'src/assets/icons/customIcons/CreditsLarge'
import { LectourLargeIcon } from 'src/assets/icons/customIcons/LectourLarge'
import { LibraryLargeIcon } from 'src/assets/icons/customIcons/LibraryLarge'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import NoPhoto from 'src/imgs/no-photo.jpg'
import InfoCard from 'src/tailwind/components/InfoCard'
import { LecturerCard } from 'src/tailwind/components/LecturerCard'
import { Section } from 'src/tailwind/components/Section'
import HomeScheduleSection from 'src/views/components/HomeScheduleSection'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import HomeNewsSection from '../news/HomeNewsSection'

export default function StudentHomePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()

  return (
    <div>
      <Section homePage>
        <SuspenseWrapper>
          <Information />
        </SuspenseWrapper>
      </Section>
      <HomeNewsSection />
      <HomeScheduleSection />
      <Section homePage title={t('lecturer:lecturer')} icon={<LectourLargeIcon />}>
        <SuspenseWrapper>
          <Lecturers />
        </SuspenseWrapper>
      </Section>
    </div>
  )
}

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  arrows: true,
  rows: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true,
      },
    },
  ],
}

const sliderSettingsWithArrowsDisabled = {
  ...sliderSettings,
  arrows: false,
}

function Lecturers(): React.ReactElement {
  const t = useTranslatable()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const { data: lecturers } = useApi({
    endpoint: Api.getStudentLecturersCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })

  if (lecturers.length === 0) {
    return (
      <div
        className="mr-2 border-0 border-lightSecondaryWarning text-center text-primaryTextColor"
        style={{ borderRadius: '8px' }}
        role="alert"
        data-testid="lecturers/empty"
      >
        {t('error:could_not_find_lecturers_for_selected_courses')}
      </div>
    )
  }
  const sliderCount = lecturers.length > 2 ? 3 : lecturers.length
  return (
    <Slider
      {...sliderSettings}
      arrows={sliderCount !== 1}
      dots={sliderCount !== 1}
      slidesToShow={sliderCount}
      slidesToScroll={sliderCount}
    >
      {lecturers.map((lecturer) => (
        <LecturerCard
          data-testid={`lecturer/${lecturer.id}`}
          to={`/${locale}/users/${lecturer.uid}`}
          key={lecturer.id}
          lecturer={lecturer.fullName}
          course={lecturer.group?.course?.name ?? ''}
          photo={
            <span style={{ fontSize: '1.25rem' }}>
              <img
                src={lecturer.photoUrl != null && lecturer.photoUrl.length > 0 ? lecturer.photoUrl : NoPhoto}
                className="rounded-full"
              />
            </span>
          }
          group={lecturer.group?.name ?? ''}
          classNames={{ topDiv: sliderCount === 1 ? 'max-w-[50%]' : '' }}
        />
      ))}
    </Slider>
  )
}

function Information(): JSX.Element {
  const t = useTranslatable()
  const userState = useUserState()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const { data: rating } = useApi({
    endpoint: Api.getStudentRating,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const functionalityIsNotLimited = userState.data?.regime.functionalIsLimited == false
  const sliderCount = functionalityIsNotLimited ? 3 : 1

  return (
    <Slider {...sliderSettingsWithArrowsDisabled} dots={false} slidesToShow={sliderCount} slidesToScroll={sliderCount}>
      {functionalityIsNotLimited && (
        <InfoCard
          title={t('library:quantity')}
          to={`/${locale}/library`}
          icon={<LibraryLargeIcon />}
          quantity={userState.data != null ? userState.data.libraryBalance : ''}
        />
      )}
      {functionalityIsNotLimited && (
        <InfoCard
          title={t('billing:financial_information')}
          to={`/${locale}/student/billing/balance`}
          icon={<BalanceLargePositiveIcon />}
          quantity={userState.data?.billingBalance != null ? (userState.data.billingBalance / 100).toFixed(2) : ''}
        />
      )}
      <InfoCard
        title={t('person:rating')}
        to={`/${locale}/student/rating`}
        icon={<CreditsLargeIcon />}
        quantity={rating.averagePoint?.toFixed(2) ?? 0}
        text={t('person:current_rating')}
        className={functionalityIsNotLimited ? '' : 'max-w-[50%]'}
      />
    </Slider>
  )
}
